@import "styles/arcade";

.footer {
    @apply border-t pt-16;

    @screen md {
        @apply pt-12;
    }

    @screen sm {
        @apply mb-16 pt-4;
    }
}

.col {
    @apply row-start-1 col-span-2 text-sm;

    & li {
        @extend %arcadeFooter2;
    }

    @screen md {
        @apply row-start-auto col-span-4;
    }

    @screen sm {
        @apply row-start-auto col-span-4 mb-3;
    }
}

.localeSwitcher {
    @apply row-start-1 col-span-2 text-sm;

    @screen md {
        @apply row-start-auto col-start-7 col-span-2;
    }

    @screen sm {
        @apply row-start-auto col-span-4;
    }
}

.colSmall {
    @apply row-start-2 col-span-2 text-xs mt-4;

    @screen md {
        @apply row-start-auto col-span-4;
    }

    @screen sm {
        @apply row-start-auto col-span-4;
    }
}

.colHeader {
    @extend %arcadeFooter1;
    @apply mb-4;
}

.legalText {
    @extend %arcadeFooter2;
    @apply text-gray-13 row-start-2 col-span-4 text-xs mt-4;

    a {
        @apply underline text-initial;
    }
    @screen md {
        @apply row-start-auto col-span-8;
    }

    @screen sm {
        @apply row-start-auto col-span-4;
    }
}

.acknowledgementText {
    @apply row-start-auto col-span-full text-xs mt-4;
}

.copyright {
    @apply row-start-4 w-28 mb-10;
    .copyrightText {
        @extend %arcadeFooter2;
        // @apply text-xs font-normal text-gray-9;
        @apply text-gray-13;
    }

    @screen md {
        @apply row-start-auto mt-4 mb-4;
    }

    @screen sm {
        @apply row-start-auto mt-4 mb-4;
    }
}

.logo {
    @apply row-start-4 w-28 mb-10;
    grid-column-start: -3;

    @screen md {
        grid-column-start: 1;
        @apply row-start-auto mt-4 mb-0;
    }

    @screen sm {
        grid-column-start: 1;
        @apply row-start-auto mt-4 mb-0;
    }
}

.languageLabel {
    @apply row-start-3 col-span-2;

    @screen md {
        @apply hidden;
    }

    @screen sm {
        @apply hidden;
    }
}